import React, { FunctionComponent } from 'react'
import { WidgetBase } from 'Shared/components/builders/WidgetBuilder'
import WidgetContainer from 'Shared/components/builders/WidgetContainer'

import EasySoftwareCards, {
  EasySoftwareCardsType,
} from '../EasySoftwareCards/EasySoftwareCards'

export interface WidgetEasySoftwareCardsType
  extends WidgetBase,
  EasySoftwareCardsType {
  contentType: 'EasySoftwareCards'
}

const WidgetEasySoftwareCards: FunctionComponent<WidgetEasySoftwareCardsType> = (
  props
) => (
  <WidgetContainer
    {...props.puxWidgetProperties}
    {...props.puxWidgetAnimation}
    {...props.metadata}
  >
    <EasySoftwareCards
      displayText={props.displayText}
      cardsList={props.cardsList}
      headingsColor={props.headingsColor}
    />
  </WidgetContainer>
)

export default WidgetEasySoftwareCards
