import './EasySoftwareCards.scss'

import React, { FunctionComponent } from 'react'

import EasySoftwareCardItem, {
  EasySoftwareCardItemType,
} from './EasySoftwareCardItem'
import EasySoftwareProductCard, {
  EasySoftwareProductCardType,
} from './EasySoftwareProductCard'

export enum CardHeadingColor {
  GRAY = `gray`,
  RED = `red`,
}

// TODO: contentItems: any
export interface EasySoftwareCardsType {
  displayText: string
  cardsList: {
    contentItems: any
  }
  headingsColor: CardHeadingColor
}

const rootClass = `cards`

const EasySoftwareCards: FunctionComponent<EasySoftwareCardsType> = (props) => (
  <div className={rootClass}>
    {props.displayText !== null && (
      <h2 className={`${rootClass}-title`}>{props.displayText}</h2>
    )}
    <div className={`${rootClass}-items`}>
      {props.cardsList &&
        props.cardsList.contentItems &&
        props.cardsList.contentItems.map((item: any) => {
          switch (item.contentType) {
            case `EasySoftwareCardItem`:
              return (
                <EasySoftwareCardItem
                  data={item}
                  key={item.contentItemId}
                  headingsColor={props.headingsColor}
                />
              )
            case `EasySoftwareProductCard`:
              return (
                <EasySoftwareProductCard
                  data={item}
                  key={item.contentItemId}
                  headingsColor={props.headingsColor}
                />
              )
            default:
              return ``
          }
        })}
    </div>
  </div>
)

export default EasySoftwareCards
