import React, { FunctionComponent } from 'react'
import PuxWysiwyg, {
  PuxWysiwygHtmlBodyType,
} from 'Shared/components/PuxWysiwyg/PuxWysiwyg'

import PuxMediaSelector, {
  PuxMediaSelectorType,
} from '../content/mediaImage/PuxMediaSelector'
import PuxButton, { PuxButtonType } from '../PuxButton/PuxButton'
import PuxVideo, { PuxVideoType } from '../PuxVideo/PuxVideo'
import { CardHeadingColor } from './EasySoftwareCards'

export interface EasySoftwareProductCardType {
  contentItemId: string
  contentType: string
  displayText: string
  productCardBackground: PuxMediaSelectorType
  productCardPerex: PuxWysiwygHtmlBodyType
  puxButton: PuxButtonType
  puxVideo: PuxVideoType
}

export interface EasySoftwareProductCardProps {
  data: EasySoftwareProductCardType
  headingsColor: CardHeadingColor
}

const rootClass = `card-item`
const rootClassSuffix = ' product-card'

const EasySoftwareProductCardItem: FunctionComponent<EasySoftwareProductCardProps> = (
  props
) => {
  const primaryButton = props.data.puxButton

  return (
    <div className={rootClass + rootClassSuffix}>
      {props.data.productCardBackground.mediaSelectorImage.resizePaths[0] && (
        <div className={`${rootClass}-image`}>
          <PuxMediaSelector {...props.data.productCardBackground} />
        </div>
      )}

      <div className={`${rootClass}-perex`}>
        <PuxWysiwyg content={props.data.productCardPerex} />
      </div>

      <div className={`${rootClass}-buttons`}>
        {primaryButton?.buttonLink.text && <PuxButton {...primaryButton} />}
        {props.data.puxVideo?.videoYTID?.html && (
          <div className={`${rootClass}-buttons-video`}>
            <PuxVideo
              videoStructuredDataContentUrl={props.data.puxVideo?.videoStructuredDataContentUrl}
              videoStructuredDataDescription={props.data.puxVideo?.videoStructuredDataDescription}
              videoStructuredDataName={props.data.puxVideo?.videoStructuredDataName}
              videoStructuredDataUploadDate={props.data.puxVideo?.videoStructuredDataUploadDate}
              videoThumbnail={props.data.puxVideo?.videoThumbnail}
              videoYTID={props.data.puxVideo?.videoYTID}/>
          </div>
        )}
      </div>
    </div>
  )
}

export default EasySoftwareProductCardItem
